import React, { useState, useEffect } from 'react';

function Aniv8({ TimeZ, U1 }) {
  const setAniv4 = useState('');
  const [aniv8, setAniv8] = useState('');

  useEffect(() => {
    const intervalID = setInterval(() => {
      checkAnniversaries();
    }, 0);

    return () => clearInterval(intervalID);
  }, []);

  function checkAnniversaries() {
    const d = new Date();
    const day = String(d.getUTCDate()).padStart(2, '0');
    const month = String(d.getUTCMonth() + 1).padStart(2, '0');
    const year = d.getUTCFullYear();
    const today = day + "/" + month;
    const hours = String(d.getUTCHours()).padStart(2, '0');

    if (TimeZ === 'UTC+1' && (U1 >= 24 || U1 >= 0)) {
      if (today === '20/04') {
        const yearsAgo = year - 2020;
        setAniv4('(Today at ${yearsAgo} years ago)');
      } else if (today === '17/08') {
        const yearsAgo = year - 2019;
        setAniv8('(Today at ${yearsAgo} years ago)');
      }
    } else if (TimeZ === 'UTC+0') {
      if ((today === '20/04' && (hours >= 24 || hours >= 0)) || today === '17/08') {
        if (today === '20/04') {
            const yearsAgo = year - 2020;
            setAniv4('(Today at ${yearsAgo} years ago)');
        } else if (today === '17/08') {
            const yearsAgo = year - 2019;
            setAniv8('(Today at ${yearsAgo} years ago)');
        }
      }
    }
  }

  return (
    <u id="aniv8">{aniv8}</u>
  );
}

export default Aniv8;