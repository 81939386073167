import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import '../App.css';
import Body from '../js/Body';
import Footer from '../js/Footer';

function Home() {
  
  // Check if there's a theme preference stored in localStorage, otherwise default to light mode
  const savedTheme = localStorage.getItem('theme');
  const [darkMode, setDarkMode] = useState(savedTheme === 'dark');

  // Save the theme preference to localStorage whenever it changes
  useEffect(() => {
      localStorage.setItem('theme', darkMode ? 'dark' : 'light');
  }, [darkMode]);

  const toggleDarkMode = () => {
      setDarkMode(!darkMode);
  };

  return (
    <div className={`App ${darkMode ? 'dark' : 'light'}`}>
      {/* Navigation Section */}
      <header>
        <nav className="navbar navbar-expand-md navbar-dark bg-dark justify-content-between">
          <a className="navbar-brand" href="/">
            <img src="favicon.ico" alt="" />
            <span>D3V1L's Server</span>
          </a>
          <div className="container-h justify-content-center collapse navbar-collapse">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link className="nav-link active" to="/">Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about">About</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact">Contact</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link disabled" to="/register">Register</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link disabled" to="/login">Login</Link>
              </li>
              <li className="nav-item">
                <button className="theme-btn" onClick={toggleDarkMode}>
                {darkMode ? 'Light Mode' : 'Dark Mode'}
                </button>
              </li>
            </ul>
          </div>
        </nav>
      </header>
      <Body />
            
      {/* Footer Section */}
      <Footer />

      <style>{`
        ::-webkit-scrollbar {
        background-color: ${darkMode ? '#424242' : '#f1f1f1'};
        width: 16px;
        }
        ::-webkit-scrollbar-thumb {
        background-color: ${darkMode ? '#7b7b7b' : '#a8a8a8'};
        }
        }
        ::-webkit-scrollbar-button {
        background-color: ${darkMode ? '#7b7b7b' : '#a8a8a8'};
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAQAAAAngNWGAAAAP0lEQVR42mNkAAIAAAoAAv/lxKUAAAAASUVORK5CYII=');
        background-repeat: no-repeat;
        background-size: contain;
        }
      `}</style>

    </div>
  );
}
export default Home;