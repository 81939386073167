import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from '../js/Footer';

function Contact() {
    // Check if there's a theme preference stored in localStorage, otherwise default to light mode
    const savedTheme = localStorage.getItem('theme');
    const [darkMode, setDarkMode] = useState(savedTheme === 'dark');

    // Save the theme preference to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('theme', darkMode ? 'dark' : 'light');
    }, [darkMode]);

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
    };

    return (
        <div className={`App ${darkMode ? 'dark' : 'light'}`}>
            <header>
                <nav className="navbar navbar-expand-md navbar-dark bg-dark justify-content-between">
                <a className="navbar-brand" href="/">
                    <img src="favicon.ico" alt="" />
                    <span>D3V1L's Server</span>
                </a>
                <div className="container-h justify-content-center collapse navbar-collapse">
                    <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <Link className="nav-link" to="/">Home</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/about">About</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link active" to="/contact">Contact</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link disabled" to="/register">Register</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link disabled" to="/login">Login</Link>
                    </li>
                    <li className="nav-item">
                        <button className="theme-btn" onClick={toggleDarkMode}>
                        {darkMode ? 'Light Mode' : 'Dark Mode'}
                        </button>
                    </li>
                    </ul>
                </div>
                </nav>
            </header>

            <div className="container my-5" style={{ lineHeight: '1' }}>
                <h1 style={{ textAlign: 'center' }}>My Contacts/Socials</h1>
                <h5 style={{ textAlign: 'center' }}>Here you can contact me through my social networks and join my groups.</h5>
                <br />
                <p>Instagram: <a className='custom-link' href="https://instagram.com/diogo.santos9/" target="_blank" rel="noopener noreferrer">diogo.santos9</a></p>
                <p>Discord: <a className='custom-link' href="https://discord.com/users/579343897109725201" target="_blank" rel="noopener noreferrer">d3v1l9</a></p>
                <p>Twitter: <a className='custom-link' href="https://twitter.com/D3V1L_9" target="_blank" rel="noopener noreferrer">@D3V1L_9</a></p>
                <p>Steam: <a className='custom-link' href="https://steamcommunity.com/id/D3V1L_69/" target="_blank" rel="noopener noreferrer">☣︎ D3V1L ☣</a></p>
                <p>Twitch: <a className='custom-link' href="https://twitch.tv/d3v1l9" target="_blank" rel="noopener noreferrer">D3V1L9</a></p>
                <p>Business Mail: <a className='custom-link' href="mailto:d3v1l.business9@gmail.com">d3v1l.business9@gmail.com</a></p>
                <p>Discord Group: <a className='custom-link' href="https://discord.com/invite/sg6ZRHCpm8" target="_blank" rel="noopener noreferrer">The Ghost Samurais{/*👻*/}</a></p>
                <p>Steam Group: <a className='custom-link' href="https://steamcommunity.com/groups/The_GhostSZ" target="_blank" rel="noopener noreferrer">The Ghost Samurais</a></p>
                <h1 style={{ textAlign: 'center' }}>My Partner Socials</h1>
                <p>Instagram: <a className='custom-link' href="https://instagram.com/meechyk_/" target="_blank" rel="noopener noreferrer">meechyk_</a></p>
            </div>

            <Footer />

            <style>{`
                ::-webkit-scrollbar {
                background-color: ${darkMode ? '#424242' : '#f1f1f1'};
                width: 16px;
                }
                ::-webkit-scrollbar-thumb {
                background-color: ${darkMode ? '#7b7b7b' : '#a8a8a8'};
                }
                }
                ::-webkit-scrollbar-button {
                background-color: ${darkMode ? '#7b7b7b' : '#a8a8a8'};
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAQAAAAngNWGAAAAP0lEQVR42mNkAAIAAAoAAv/lxKUAAAAASUVORK5CYII=');
                background-repeat: no-repeat;
                background-size: contain;
                }
            `}</style>

        </div>
    );
}
export default Contact;