import React, { useState } from 'react';

function LastUpdated() {
  const lastUpdated = useState('19 February 2024, 03:57:00 AM');

  return (
    <b id="LastUpdated">{lastUpdated}</b>
  );
}

export default LastUpdated;
