import React from 'react';
import '../App.css';
import Version from './scripts/Version';
import LastUpdated from './scripts/LastUp';
import CDate from './scripts/CDate';

function Footer({ darkMode }) {
  return (
    <footer className="footer unselectable">
      <div className="footer-info">
            <p style={{ marginBottom: '8px' }}>Website Version: <Version /></p>
            <p>Last updated on:</p> <p><LastUpdated /></p>
      </div>
      <div className="footer-time">
            <p>Local time: <CDate /> </p>
      </div>
      <div className="footer-copy">© <b>2020-{new Date().getFullYear()} <a href="https://instagram.com/diogo.santos9/" target="_blank" rel="noopener noreferrer">D3V1L</a>. All rights reserved.</b></div>
    </footer>
  );
}

export default Footer;
