import React, { useState } from 'react';

function Version() {
  const version = useState('2.1.7');
 
  return (
      <b id="Version">{version}</b>
  );
}

export default Version;
