import React, { useState, useEffect } from 'react';

function SVdate() {
  const [dateTime, setDateTime] = useState('');
  const setTimeZone = useState('UTC');

  useEffect(() => {
    const intervalID = setInterval(() => {
      UTC();
    }, 0);

    return () => clearInterval(intervalID);
  }, [UTC]);

  function UTC() {
    const d = new Date();
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const year = d.getFullYear();
    let hours = d.getUTCHours();
    const minutes = String(d.getUTCMinutes()).padStart(2, '0');
    const seconds = String(d.getUTCSeconds()).padStart(2, '0');
    let timePeriod = 'AM';

    // Convert hours to 12-hour format and determine AM/PM
    if (hours >= 12) {
      timePeriod = 'PM';
      hours = hours === 12 ? 12 : hours - 12;
    }
    if (hours === 0) {
      hours = 12; // 0 hour corresponds to 12 AM
    }

    const time = String(hours).padStart(2, '0') + ':' + minutes + ':' + seconds + ' ' + timePeriod;
    const dateStr = day + "/" + month + "/" + year;

    if (month === '03') {
      const dtM = 'Sun Mar ' + day + year;
      if (dtM === lastSunday(year, 3)) {
        if (hours >= 1) {
          const TimeZ = 'UTC+1';
          setDateTime(dateStr + ' ' + time);
          setTimeZone(TimeZ);
        } else {
          const newHours = String(d.getUTCHours() + 1).padStart(2, '0');
          const newTime = String(newHours).padStart(2, '0') + ':' + minutes + ':' + seconds + ' ' + timePeriod;
          setDateTime(dateStr + ' ' + newTime);
          setTimeZone('UTC+1');
        }
      } else if (dtM > lastSunday(year, 3)) {
        const TimeZ = 'UTC+1';
        setDateTime(dateStr + ' ' + time);
        setTimeZone(TimeZ);
      } else {
        setDateTime(dateStr + ' ' + time);
        setTimeZone('UTC');
      }
    } else if (month === '10') {
      const dtO = 'Sun Oct ' + day + year;
      if (dtO === lastSunday(year, 10)) {
        if (hours >= 2) {
          setDateTime(dateStr + ' ' + time);
          setTimeZone('UTC');
        } else {
          const newHours = String(d.getUTCHours() + 1).padStart(2, '0');
          const newTime = String(newHours).padStart(2, '0') + ':' + minutes + ':' + seconds + ' ' + timePeriod;
          setDateTime(dateStr + ' ' + newTime);
          setTimeZone('UTC+1');
        }
      } else if (dtO < lastSunday(year, 10)) {
        setDateTime(dateStr + ' ' + time);
        setTimeZone('UTC+1');
      } else {
        setDateTime(dateStr + ' ' + time);
        setTimeZone('UTC');
      }
    } else if (month >= '04' && month <= '09') {
      setDateTime(dateStr + ' ' + time);
      setTimeZone('UTC+1');
    } else {
      setDateTime(dateStr + ' ' + time);
      setTimeZone('UTC');
    }
  }

  function lastSunday(year, month) {
    const d = new Date(year, month - 1, 1);
    d.setDate(d.getDate() + 6 - d.getDay()); // Move to the last day of the week
    return d.toLocaleDateString();
  }

  //const U1 = String(new Date().getUTCHours() + 1).padStart(2, '0');

  return (
      <b id="UTCd">{dateTime}</b>
  );
}

export default SVdate;
