import React, { useState, useEffect } from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import SVdate from '../js/scripts/SVdate'
import TimeZone from '../js/scripts/TimeZone'
import Age from '../js/scripts/Age';
import Footer from '../js/Footer';
import Aniv4 from '../js/scripts/Aniv4';
import Aniv8 from '../js/scripts/Aniv8';

function About() {
    // Check if there's a theme preference stored in localStorage, otherwise default to light mode
    const savedTheme = localStorage.getItem('theme');
    const [darkMode, setDarkMode] = useState(savedTheme === 'dark');

    // Save the theme preference to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('theme', darkMode ? 'dark' : 'light');
    }, [darkMode]);

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
    };

    return (
        <div className={`App ${darkMode ? 'dark' : 'light'}`}>
            <header>
                <nav className="navbar navbar-expand-md navbar-dark bg-dark justify-content-between">
                <a className="navbar-brand" href="/">
                    <img src="favicon.ico" alt="" />
                    <span>D3V1L's Server</span>
                </a>
                <div className="container-h justify-content-center collapse navbar-collapse">
                    <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <Link className="nav-link" to="/">Home</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link active" to="/about">About</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/contact">Contact</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link disabled" to="/register">Register</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link disabled" to="/login">Login</Link>
                    </li>
                    <li className="nav-item">
                        <button className="theme-btn" onClick={toggleDarkMode}>
                        {darkMode ? 'Light Mode' : 'Dark Mode'}
                        </button>
                    </li>
                    </ul>
                </div>
                </nav>
            </header>
            <div className="container my-5">
                {/* About Me */}
                <div style={{ lineHeight: 1.2 }}>
                    <h1 style={{ textAlign: 'center' }}>About Me</h1>
                    <p>I'm Diogo Santos, AKA <b>D3V1L</b>, AKA <b>D3V1L9</b>.</p>
                    <p>I'm <Age /> years old interested in a variaty of things such as programming(more frontend than backend), host my own servers, bug testing(not so cool but whatever, gotta do it anyways), play and hang out with my friends or family.</p> 
                    {/* You can add more JSX here */}
                </div>
                {/* About The Server */}
                <div style={{ lineHeight: 1.2 }}>
                    <h1 style={{ textAlign: 'center' }}>About The Server</h1>
                    <h3 style={{ textAlign: 'left' }}>History</h3>
                    <p>The server was created on <b>17 of August of 2019 <Aniv8 /></b>, by <a className='custom-link' href="https://instagram.com/diogo.santos9/"><b>D3V1L</b></a>.</p>
                    <p>The idea of building a MTA Server urged from a conversation between me and my brother, <a className='custom-link' href="https://instagram.com/meechyk_/">meechyk_</a>, 
                    we were playing on a MTA server and just came to our minds build our own MTA Server so we started digging and I've iniciated and keeping working 
                    on the process, I work on the harder things when I got time and <a className='custom-link' href="https://instagram.com/meechyk_/">meechyk_</a> helps me by bug testing and dig up some cool stuff.</p>
                    {/*
                    <p>It's still being developed because it's hard to find good scripts and it takes much time to do it on my own, time that I don't have. 
                        The basics are there but it's not finished yet so it's still protected by password.</p> */}
                    <h3 style={{ textAlign: 'left' }}>Information</h3>
                    <ul>
                        <li>Server Name: <b>D3V1L's Server</b></li>
                        <li>Server IP & Port: <a className='custom-link' href="mtasa://85.240.225.145:22003">85.240.225.145:22003</a></li>
                        <li><b>Protected by Password</b></li>
                        <li>Server Time: <SVdate /> </li>
                        <li>Timezone: <TimeZone /> </li>
                        <li>Max Players: <b>20</b></li>
                        <li>Max FPS: <b>100</b></li>
                        <li>Voice: <b>Disabled</b></li>
                        <li>Min Cliente Version: <b>1.5.9-9.21554.0</b></li>
                    </ul>
                </div>
                {/* About This Website */}
                <div style={{ lineHeight: 1.2 }}>
                    <h1 style={{ textAlign: 'center' }}>About This Website</h1>
                    <h3 style={{ textAlign: 'left' }}>History</h3>
                    <p>This website was created on <b>20 of April of 2020 <Aniv4 /></b>, by <a className='custom-link' href="https://instagram.com/diogo.santos9/"><b>D3V1L</b></a>.</p>
                    <p>It's still being developed, that's why the "<u><b>Register</b></u>" & "<u><b>Login</b></u>" buttons on the navigation bar are <u><b>disabled</b></u> for now. My "backend" knowlage isn't that good yet and it's giving me some errors while trying to redirect on regist & login forms.</p>
                    <h3 style={{ textAlign: 'left' }}>Content</h3>
                    <p>In this website you can buy <u><b>4 types</b></u> of <u><b>VIP</b></u> for "<a className='custom-link' href="mtasa://85.240.225.145:22003">D3V1L's Server</a>" on MTA:SA, with <u><b>secure payments</b></u> through <u><b>Paypal</b></u>. You can also know a little bit about me, about this website and my social media so <u><b>you can reach me if you have any doubt/problem that I can teach/fix</b></u>.</p>
                    <p>This website will also have a "<u><b>Regist</b></u>" & "<u><b>Login</b></u>" form pages that are already done at frontend part and still in development the frontend part. This will <u><b>co-work</b></u> with a <u><b>forum page that I'm still thinking</b></u> how it will be the <u><b>design and features</b></u>.</p>
                </div>
            </div>
            {/* Footer Section */}
            <Footer />

            <style>{`
                ::-webkit-scrollbar {
                background-color: ${darkMode ? '#424242' : '#f1f1f1'};
                width: 16px;
                }
                ::-webkit-scrollbar-thumb {
                background-color: ${darkMode ? '#7b7b7b' : '#a8a8a8'};
                }
                }
                ::-webkit-scrollbar-button {
                background-color: ${darkMode ? '#7b7b7b' : '#a8a8a8'};
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAQAAAAngNWGAAAAP0lEQVR42mNkAAIAAAoAAv/lxKUAAAAASUVORK5CYII=');
                background-repeat: no-repeat;
                background-size: contain;
                }
            `}</style>

        </div>
    );
}
export default About;