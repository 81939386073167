import React, { useState, useEffect } from 'react';

function CDate() {
  const [dateTime, setDateTime] = useState('');
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = new Date();
      const month = String(currentTime.getMonth() + 1).padStart(2, '0');
      const day = String(currentTime.getDate()).padStart(2, '0');
      const year = currentTime.getFullYear();
      const hours = String(currentTime.getHours()).padStart(2, '0');
      const minutes = String(currentTime.getMinutes()).padStart(2, '0');
      const seconds = String(currentTime.getSeconds()).padStart(2, '0');

      const text = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
      setDateTime(text);
    }, 0);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
	<b id="Cdate">{dateTime}</b>
  );
}

export default CDate;