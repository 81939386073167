import React from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function Body() {
  return (
    <div className="container my-4 unselectable">
      {/* Server IP */}
      <div className="ip">
        <big>
          Server IP: <a className='custom-link' href="mtasa://85.240.225.145:22003" target="_blank" rel="noopener noreferrer">85.240.225.145:22003</a>
        </big>
      </div>

      {/* Banner */}
      <div className="text-center py-4">
        <img className="banner-img" alt="" src="/img/banner.png" />
      </div>

      {/* VIP Intro */}
      <div className="vip-intro text-center py-4">
        <big className="vip-intro-t">Meet our VIP plans!</big><br />
        <small>Buying a VIP plan on D3V1L's Server never was so easy, fast, and safe!</small>
      </div>
            {/* Prices */}
            <div className="justify-content-center align-items-center prices-section">
                <div className="row text-center py-4">
                    <div className="col">
                        <span className="vip-price bronze">
                        Bronze VIP<br />
                        <small className="vips-font">2.50€</small>
                        </span>
                    </div>
                    <div className="col">
                        <span className="vip-price silver">
                        Silver VIP<br />
                        <small className="vips-font">7.50€</small>
                        </span>
                    </div>
                    <div className="col">
                        <span className="vip-price gold">
                        Gold VIP<br />
                        <small className="vips-font">15€</small>
                        </span>
                    </div>
                    <div className="col">
                        <span className="vip-price premium">
                        Premium VIP<br />
                        <small className="vips-font">30€</small>
                        </span>
                    </div>
                </div>

                {/* VIP & PAYPAL Images */}
                <div className="row text-center">
                    <div className="col">
                    <img className="paypal-img" alt="" src="/img/PayPal.png" /><br />
                    <img alt="" src="/img/4_bronze.png" />
                    </div>
                    <div className="col">
                    <img className="paypal-img" alt="" src="/img/PayPal.png" /><br />
                    <img alt="" src="/img/3_silver.png" />
                    </div>
                    <div className="col">
                    <img className="paypal-img" alt="" src="/img/PayPal.png" /><br />
                    <img alt="" src="/img/2_gold.png" />
                    </div>
                    <div className="col">
                    <img className="paypal-img" alt="" src="/img/PayPal.png" /><br />
                    <img alt="" src="/img/1_premium.png" />
                    </div>
                </div>

                {/* PAYPAL Buttons */}
                <div className="row text-center">
                    <div className="col">
                    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                        <input type="hidden" name="cmd" value="_s-xclick" />
                        <input type="hidden" name="hosted_button_id" value="3G92F85KHWTWC" />
                        <input className="paypal-btn" type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal" />
                    </form>
                    </div>
                    <div className="col">
                    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                        <input type="hidden" name="cmd" value="_s-xclick" />
                        <input type="hidden" name="hosted_button_id" value="6QE2JP84JKPSA" />
                        <input className="paypal-btn" type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal" />
                    </form>
                    </div>
                    <div className="col">
                    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                        <input type="hidden" name="cmd" value="_s-xclick" />
                        <input type="hidden" name="hosted_button_id" value="YASKR9U7USQW8" />
                        <input className="paypal-btn" type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal" />
                    </form>
                    </div>
                    <div className="col">
                    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                        <input type="hidden" name="cmd" value="_s-xclick" />
                        <input type="hidden" name="hosted_button_id" value="2HWYNGXU3TXFS" />
                        <input className="paypal-btn" type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal" />
                    </form>
            </div>
        </div>
      </div>



      {/* Bottom Section */}
      <div className="text-center py-4">
        <img alt="" src="/img/corp.png" />
        <big>
          <span>
            <big>Join us!</big>
            <br /><br />Respectful Staff!
            <br />Fast Mod Downloads!
          </span>
        </big>
        <br /><br />
        <button className="bottom-btn"><a className="nav-link bottom-btn" href="mtasa://85.240.225.145:22003">Click here to connect!</a></button>
      </div>
    </div>
  );
}
export default Body;